/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/jquery-number@2.1.6/jquery.number.min.js
 * - /npm/bootstrap-validator@0.11.9/js/validator.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/lightgallery@1.6.12/dist/js/lightgallery-all.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
